$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-adgroups";
    	src: url("#{$glyphDir}glyph-font-adgroups.eot?a7223770-4c46-4af4-a576-e927d5bac370?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-adgroups.woff?a7223770-4c46-4af4-a576-e927d5bac370") format("woff"),
    		 url("#{$glyphDir}/glyph-font-adgroups.ttf?a7223770-4c46-4af4-a576-e927d5bac370") format("truetype");
}

.glyph-family-adgroups {
	line-height: inherit;
}

.glyph-family-adgroups:before {
	font-family: glyph-font-adgroups !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-adgroups:after {
	font-family: glyph-font-adgroups !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-adgroups-chart:before {
    content: "\ea01";
}
.glyph-adgroups-chart-after:after {
    content: "\ea01";
}

.glyph-adgroups-edit:before {
    content: "\ea02";
}
.glyph-adgroups-edit-after:after {
    content: "\ea02";
}

.glyph-adgroups-pencil-lg:before {
    content: "\ea03";
}
.glyph-adgroups-pencil-lg-after:after {
    content: "\ea03";
}

.glyph-adgroups-recommendations:before {
    content: "\ea04";
}
.glyph-adgroups-recommendations-after:after {
    content: "\ea04";
}

.glyph-adgroups-recommendations-large:before {
    content: "\ea05";
}
.glyph-adgroups-recommendations-large-after:after {
    content: "\ea05";
}

