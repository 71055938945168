$font-url: '/fonts';
@import 'component-core.scss';

.glyph {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;

    &.glyph--clickable {
        cursor: pointer;
    }

    &__inner {
        text-align: center;
        display: inline-block;
        width: 100%;
    }

    &.disabled {
        cursor: default;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__blue-button.scss';
    @import 'themes/theme__close-button.scss';
    @import 'themes/theme__pill-close-button.scss';
    @import 'themes/theme__recommendation-indicator.scss';
    @import 'themes/theme__slider-button.scss';
    @import 'themes/theme__toastie.scss';
}
