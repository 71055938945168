@include theme('toolbar-action') {
    &.button--flat.button--type-action {
        @include toolbarAction();
    }
}

@include theme('toolbar-dropdown-action') {
    &.button--flat.button--type-action {
        @include toolbarAction($color: inherit, $hover: inherit);
        margin-right: 0;
        width: 100%;

        .button__content {
            justify-content: flex-start;
        }
    }
}
