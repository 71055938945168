@include theme('big-button') {
    &.button--type-secondary.button--standard {
        width: 180px;
        height: 164px;
        background-color: grayscaleColor(1); // White

        .big-button__icon-container {
            width: 144px;
            height: 120px;
            display: inline-block;
            padding-top: 22px;
        }

        .big-button__content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .big-button-control__title {
            height: 42px;
        }

        .glyph {
            font-size: 74px;
        }
    }
}
