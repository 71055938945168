$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-autoopt";
    	src: url("#{$glyphDir}glyph-font-autoopt.eot?a7223770-4c46-4af4-a576-e927d5bac370?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-autoopt.woff?a7223770-4c46-4af4-a576-e927d5bac370") format("woff"),
    		 url("#{$glyphDir}/glyph-font-autoopt.ttf?a7223770-4c46-4af4-a576-e927d5bac370") format("truetype");
}

.glyph-family-autoopt {
	line-height: inherit;
}

.glyph-family-autoopt:before {
	font-family: glyph-font-autoopt !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-autoopt:after {
	font-family: glyph-font-autoopt !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-autoopt-ad-environment:before {
    content: "\ea01";
}
.glyph-autoopt-ad-environment-after:after {
    content: "\ea01";
}

.glyph-autoopt-ad-format:before {
    content: "\ea02";
}
.glyph-autoopt-ad-format-after:after {
    content: "\ea02";
}

.glyph-autoopt-ads.txt.sellertype:before {
    content: "\ea03";
}
.glyph-autoopt-ads.txt.sellertype-after:after {
    content: "\ea03";
}

.glyph-autoopt-audience:before {
    content: "\ea04";
}
.glyph-autoopt-audience-after:after {
    content: "\ea04";
}

.glyph-autoopt-browser:before {
    content: "\ea05";
}
.glyph-autoopt-browser-after:after {
    content: "\ea05";
}

.glyph-autoopt-device-type:before {
    content: "\ea06";
}
.glyph-autoopt-device-type-after:after {
    content: "\ea06";
}

.glyph-autoopt-fold-placement:before {
    content: "\ea07";
}
.glyph-autoopt-fold-placement-after:after {
    content: "\ea07";
}

.glyph-autoopt-geography:before {
    content: "\ea08";
}
.glyph-autoopt-geography-after:after {
    content: "\ea08";
}

.glyph-autoopt-language:before {
    content: "\ea09";
}
.glyph-autoopt-language-after:after {
    content: "\ea09";
}

.glyph-autoopt-operating-system:before {
    content: "\ea0a";
}
.glyph-autoopt-operating-system-after:after {
    content: "\ea0a";
}

.glyph-autoopt-private-contract:before {
    content: "\ea0b";
}
.glyph-autoopt-private-contract-after:after {
    content: "\ea0b";
}

.glyph-autoopt-site:before {
    content: "\ea0c";
}
.glyph-autoopt-site-after:after {
    content: "\ea0c";
}

.glyph-autoopt-site-app:before {
    content: "\ea0d";
}
.glyph-autoopt-site-app-after:after {
    content: "\ea0d";
}

.glyph-autoopt-supply-vendor:before {
    content: "\ea0e";
}
.glyph-autoopt-supply-vendor-after:after {
    content: "\ea0e";
}

