$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-buyhomepage";
    	src: url("#{$glyphDir}glyph-font-buyhomepage.eot?a7223770-4c46-4af4-a576-e927d5bac370?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-buyhomepage.woff?a7223770-4c46-4af4-a576-e927d5bac370") format("woff"),
    		 url("#{$glyphDir}/glyph-font-buyhomepage.ttf?a7223770-4c46-4af4-a576-e927d5bac370") format("truetype");
}

.glyph-family-buyhomepage {
	line-height: inherit;
}

.glyph-family-buyhomepage:before {
	font-family: glyph-font-buyhomepage !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-buyhomepage:after {
	font-family: glyph-font-buyhomepage !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-buyhomepage-megaphone:before {
    content: "\ea01";
}
.glyph-buyhomepage-megaphone-after:after {
    content: "\ea01";
}

.glyph-buyhomepage-rocket:before {
    content: "\ea02";
}
.glyph-buyhomepage-rocket-after:after {
    content: "\ea02";
}

.glyph-buyhomepage-staggered-star:before {
    content: "\ea03";
}
.glyph-buyhomepage-staggered-star-after:after {
    content: "\ea03";
}

.glyph-buyhomepage-star:before {
    content: "\ea04";
}
.glyph-buyhomepage-star-after:after {
    content: "\ea04";
}

