$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-audience";
    	src: url("#{$glyphDir}glyph-font-audience.eot?a7223770-4c46-4af4-a576-e927d5bac370?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-audience.woff?a7223770-4c46-4af4-a576-e927d5bac370") format("woff"),
    		 url("#{$glyphDir}/glyph-font-audience.ttf?a7223770-4c46-4af4-a576-e927d5bac370") format("truetype");
}

.glyph-family-audience {
	line-height: inherit;
}

.glyph-family-audience:before {
	font-family: glyph-font-audience !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-audience:after {
	font-family: glyph-font-audience !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-audience-behavioral:before {
    content: "\ea01";
}
.glyph-audience-behavioral-after:after {
    content: "\ea01";
}

.glyph-audience-custom-audience:before {
    content: "\ea02";
}
.glyph-audience-custom-audience-after:after {
    content: "\ea02";
}

.glyph-audience-demographics:before {
    content: "\ea03";
}
.glyph-audience-demographics-after:after {
    content: "\ea03";
}

.glyph-audience-first-party-data:before {
    content: "\ea04";
}
.glyph-audience-first-party-data-after:after {
    content: "\ea04";
}

.glyph-audience-look-a-like:before {
    content: "\ea05";
}
.glyph-audience-look-a-like-after:after {
    content: "\ea05";
}

