@include theme('accordion') {
    &.button--flat {
        --mdc-theme-primary: transparent;
        
        &.button--type-primary,
        &.button--type-secondary {
            --bg-color: transparent;
        }

        &.button--type-secondary {
            &:hover,
            &:focus {
                --txt-color: #{primaryColor(1)};
            }
        }
    }
}
  
@include theme('accordion-right') {
    &.button--flat {
        --mdc-theme-primary: transparent;
        
        &.button--type-primary,
        &.button--type-secondary {
            --bg-color: transparent;
        }

        &.button--type-secondary {
            &:hover,
            &:focus {
                --txt-color: #{primaryColor(1)};
            }
        }
    }
    
    .button__content {
        flex-grow: 1;
        justify-content: space-between;
    }
}
