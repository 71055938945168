$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-default";
    	src: url("#{$glyphDir}glyph-font-default.eot?a7223770-4c46-4af4-a576-e927d5bac370?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-default.woff?a7223770-4c46-4af4-a576-e927d5bac370") format("woff"),
    		 url("#{$glyphDir}/glyph-font-default.ttf?a7223770-4c46-4af4-a576-e927d5bac370") format("truetype");
}

.glyph-family-default {
	line-height: inherit;
}

.glyph-family-default:before {
	font-family: glyph-font-default !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-default:after {
	font-family: glyph-font-default !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-default-back-arrow:before {
    content: "\ea01";
}
.glyph-default-back-arrow-after:after {
    content: "\ea01";
}

.glyph-default-cards:before {
    content: "\ea02";
}
.glyph-default-cards-after:after {
    content: "\ea02";
}

.glyph-default-checkmark:before {
    content: "\ea03";
}
.glyph-default-checkmark-after:after {
    content: "\ea03";
}

.glyph-default-clock:before {
    content: "\ea04";
}
.glyph-default-clock-after:after {
    content: "\ea04";
}

.glyph-default-close:before {
    content: "\ea05";
}
.glyph-default-close-after:after {
    content: "\ea05";
}

.glyph-default-copy-paste:before {
    content: "\ea06";
}
.glyph-default-copy-paste-after:after {
    content: "\ea06";
}

.glyph-default-copy-paste-lg:before {
    content: "\ea07";
}
.glyph-default-copy-paste-lg-after:after {
    content: "\ea07";
}

.glyph-default-delete-small:before {
    content: "\ea08";
}
.glyph-default-delete-small-after:after {
    content: "\ea08";
}

.glyph-default-do-not:before {
    content: "\ea09";
}
.glyph-default-do-not-after:after {
    content: "\ea09";
}

.glyph-default-down-arrow:before {
    content: "\ea0a";
}
.glyph-default-down-arrow-after:after {
    content: "\ea0a";
}

.glyph-default-down-arrow-filled:before {
    content: "\ea0b";
}
.glyph-default-down-arrow-filled-after:after {
    content: "\ea0b";
}

.glyph-default-download:before {
    content: "\ea0c";
}
.glyph-default-download-after:after {
    content: "\ea0c";
}

.glyph-default-edit-icon:before {
    content: "\ea0d";
}
.glyph-default-edit-icon-after:after {
    content: "\ea0d";
}

.glyph-default-empty-audience:before {
    content: "\ea0e";
}
.glyph-default-empty-audience-after:after {
    content: "\ea0e";
}

.glyph-default-exclamation:before {
    content: "\ea0f";
}
.glyph-default-exclamation-after:after {
    content: "\ea0f";
}

.glyph-default-export:before {
    content: "\ea10";
}
.glyph-default-export-after:after {
    content: "\ea10";
}

.glyph-default-file-upload-success:before {
    content: "\ea11";
}
.glyph-default-file-upload-success-after:after {
    content: "\ea11";
}

.glyph-default-folder:before {
    content: "\ea12";
}
.glyph-default-folder-after:after {
    content: "\ea12";
}

.glyph-default-forward-arrow:before {
    content: "\ea13";
}
.glyph-default-forward-arrow-after:after {
    content: "\ea13";
}

.glyph-default-geo-marker:before {
    content: "\ea14";
}
.glyph-default-geo-marker-after:after {
    content: "\ea14";
}

.glyph-default-help-button:before {
    content: "\ea15";
}
.glyph-default-help-button-after:after {
    content: "\ea15";
}

.glyph-default-information:before {
    content: "\ea16";
}
.glyph-default-information-after:after {
    content: "\ea16";
}

.glyph-default-left-arrow:before {
    content: "\ea17";
}
.glyph-default-left-arrow-after:after {
    content: "\ea17";
}

.glyph-default-library:before {
    content: "\ea18";
}
.glyph-default-library-after:after {
    content: "\ea18";
}

.glyph-default-link:before {
    content: "\ea19";
}
.glyph-default-link-after:after {
    content: "\ea19";
}

.glyph-default-linked:before {
    content: "\ea1a";
}
.glyph-default-linked-after:after {
    content: "\ea1a";
}

.glyph-default-location:before {
    content: "\ea1b";
}
.glyph-default-location-after:after {
    content: "\ea1b";
}

.glyph-default-mail:before {
    content: "\ea1c";
}
.glyph-default-mail-after:after {
    content: "\ea1c";
}

.glyph-default-menu:before {
    content: "\ea1d";
}
.glyph-default-menu-after:after {
    content: "\ea1d";
}

.glyph-default-menu-horizontal:before {
    content: "\ea1e";
}
.glyph-default-menu-horizontal-after:after {
    content: "\ea1e";
}

.glyph-default-minus:before {
    content: "\ea1f";
}
.glyph-default-minus-after:after {
    content: "\ea1f";
}

.glyph-default-new-list:before {
    content: "\ea20";
}
.glyph-default-new-list-after:after {
    content: "\ea20";
}

.glyph-default-plus:before {
    content: "\ea21";
}
.glyph-default-plus-after:after {
    content: "\ea21";
}

.glyph-default-plus-small:before {
    content: "\ea22";
}
.glyph-default-plus-small-after:after {
    content: "\ea22";
}

.glyph-default-question-circle:before {
    content: "\ea23";
}
.glyph-default-question-circle-after:after {
    content: "\ea23";
}

.glyph-default-received:before {
    content: "\ea24";
}
.glyph-default-received-after:after {
    content: "\ea24";
}

.glyph-default-return-arrow:before {
    content: "\ea25";
}
.glyph-default-return-arrow-after:after {
    content: "\ea25";
}

.glyph-default-right-arrow:before {
    content: "\ea26";
}
.glyph-default-right-arrow-after:after {
    content: "\ea26";
}

.glyph-default-sample:before {
    content: "\ea27";
}
.glyph-default-sample-after:after {
    content: "\ea27";
}

.glyph-default-search:before {
    content: "\ea28";
}
.glyph-default-search-after:after {
    content: "\ea28";
}

.glyph-default-search-with-question-mark:before {
    content: "\ea29";
}
.glyph-default-search-with-question-mark-after:after {
    content: "\ea29";
}

.glyph-default-socks:before {
    content: "\ea2a";
}
.glyph-default-socks-after:after {
    content: "\ea2a";
}

.glyph-default-sort-down:before {
    content: "\ea2b";
}
.glyph-default-sort-down-after:after {
    content: "\ea2b";
}

.glyph-default-sort-up:before {
    content: "\ea2c";
}
.glyph-default-sort-up-after:after {
    content: "\ea2c";
}

.glyph-default-status-error:before {
    content: "\ea2d";
}
.glyph-default-status-error-after:after {
    content: "\ea2d";
}

.glyph-default-status-success:before {
    content: "\ea2e";
}
.glyph-default-status-success-after:after {
    content: "\ea2e";
}

.glyph-default-table:before {
    content: "\ea2f";
}
.glyph-default-table-after:after {
    content: "\ea2f";
}

.glyph-default-template:before {
    content: "\ea30";
}
.glyph-default-template-after:after {
    content: "\ea30";
}

.glyph-default-trash-can:before {
    content: "\ea31";
}
.glyph-default-trash-can-after:after {
    content: "\ea31";
}

.glyph-default-type-to-add:before {
    content: "\ea32";
}
.glyph-default-type-to-add-after:after {
    content: "\ea32";
}

.glyph-default-unlinked:before {
    content: "\ea33";
}
.glyph-default-unlinked-after:after {
    content: "\ea33";
}

.glyph-default-up-arrow:before {
    content: "\ea34";
}
.glyph-default-up-arrow-after:after {
    content: "\ea34";
}

.glyph-default-up-arrow-filled:before {
    content: "\ea35";
}
.glyph-default-up-arrow-filled-after:after {
    content: "\ea35";
}

.glyph-default-upload:before {
    content: "\ea36";
}
.glyph-default-upload-after:after {
    content: "\ea36";
}

.glyph-default-upload-large:before {
    content: "\ea37";
}
.glyph-default-upload-large-after:after {
    content: "\ea37";
}

.glyph-default-user:before {
    content: "\ea38";
}
.glyph-default-user-after:after {
    content: "\ea38";
}

.glyph-default-video-pause:before {
    content: "\ea39";
}
.glyph-default-video-pause-after:after {
    content: "\ea39";
}

.glyph-default-video-play:before {
    content: "\ea3a";
}
.glyph-default-video-play-after:after {
    content: "\ea3a";
}

.glyph-default-view:before {
    content: "\ea3b";
}
.glyph-default-view-after:after {
    content: "\ea3b";
}

.glyph-default-windicon:before {
    content: "\ea3c";
}
.glyph-default-windicon-after:after {
    content: "\ea3c";
}

.glyph-default-wrench:before {
    content: "\ea3d";
}
.glyph-default-wrench-after:after {
    content: "\ea3d";
}

