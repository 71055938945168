@include theme('action-bar--fullpage') {
    &.button--type-secondary {
        color: grayscaleColor(1);

        &:hover:enabled,
        &:focus:enabled,
        &:active:enabled {
            color: grayscaleColor(3);
        }
    }
}
