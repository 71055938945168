$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-actionnav";
    	src: url("#{$glyphDir}glyph-font-actionnav.eot?a7223770-4c46-4af4-a576-e927d5bac370?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-actionnav.woff?a7223770-4c46-4af4-a576-e927d5bac370") format("woff"),
    		 url("#{$glyphDir}/glyph-font-actionnav.ttf?a7223770-4c46-4af4-a576-e927d5bac370") format("truetype");
}

.glyph-family-actionnav {
	line-height: inherit;
}

.glyph-family-actionnav:before {
	font-family: glyph-font-actionnav !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-actionnav:after {
	font-family: glyph-font-actionnav !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-actionnav-ad-optimization:before {
    content: "\ea01";
}
.glyph-actionnav-ad-optimization-after:after {
    content: "\ea01";
}

.glyph-actionnav-ad-rail:before {
    content: "\ea02";
}
.glyph-actionnav-ad-rail-after:after {
    content: "\ea02";
}

.glyph-actionnav-add-reporting:before {
    content: "\ea03";
}
.glyph-actionnav-add-reporting-after:after {
    content: "\ea03";
}

.glyph-actionnav-app-list:before {
    content: "\ea04";
}
.glyph-actionnav-app-list-after:after {
    content: "\ea04";
}

.glyph-actionnav-build-libraries:before {
    content: "\ea05";
}
.glyph-actionnav-build-libraries-after:after {
    content: "\ea05";
}

.glyph-actionnav-create-locations-list:before {
    content: "\ea06";
}
.glyph-actionnav-create-locations-list-after:after {
    content: "\ea06";
}

.glyph-actionnav-details-conversion-reporting:before {
    content: "\ea07";
}
.glyph-actionnav-details-conversion-reporting-after:after {
    content: "\ea07";
}

.glyph-actionnav-edit-ad-group-details:before {
    content: "\ea08";
}
.glyph-actionnav-edit-ad-group-details-after:after {
    content: "\ea08";
}

.glyph-actionnav-edit-conversion-settings:before {
    content: "\ea09";
}
.glyph-actionnav-edit-conversion-settings-after:after {
    content: "\ea09";
}

.glyph-actionnav-edit-goals-and-budget:before {
    content: "\ea0a";
}
.glyph-actionnav-edit-goals-and-budget-after:after {
    content: "\ea0a";
}

.glyph-actionnav-export-to-qa:before {
    content: "\ea0b";
}
.glyph-actionnav-export-to-qa-after:after {
    content: "\ea0b";
}

.glyph-actionnav-goals-budget-fees-rates:before {
    content: "\ea0c";
}
.glyph-actionnav-goals-budget-fees-rates-after:after {
    content: "\ea0c";
}

.glyph-actionnav-manage-fees-and-rates:before {
    content: "\ea0d";
}
.glyph-actionnav-manage-fees-and-rates-after:after {
    content: "\ea0d";
}

.glyph-actionnav-status-on:before {
    content: "\ea0e";
}
.glyph-actionnav-status-on-after:after {
    content: "\ea0e";
}

.glyph-actionnav-upload-creative:before {
    content: "\ea0f";
}
.glyph-actionnav-upload-creative-after:after {
    content: "\ea0f";
}

.glyph-actionnav-upload-creative-1:before {
    content: "\ea10";
}
.glyph-actionnav-upload-creative-1-after:after {
    content: "\ea10";
}

